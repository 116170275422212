import React, { createContext, useContext } from 'react';
import {
  arrayOf, bool, func, node, number, oneOfType, shape, string
} from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from './CalculatorContext';
import { useCalculator } from '../../hooks/useCalculator';
import { ToasterContext } from './FlooringCalculator/Toaster/ToasterProvider';

const toasterContent = ({ areaName, actionPerformed }) => {
  return (
    <div className="sui-grid sui-grid-flow-col sui-gap-1">
      <div className="sui-truncate">{areaName}</div>
      <div>{actionPerformed}</div>
    </div>
  );
};

export const CalculatorProvider = ({
  allowAdditionalCoverage,
  calculatorType,
  isFlooringDrawer,
  children,
  lineItemName,
  info,
  onCalculate,
  taxonomy,
  unitsPerCase,
  _testOverrides
}) => {
  const { label: l1Label } = taxonomy?.breadCrumbs?.[0] || { label: '' };
  const [
    calculate,
    {
      control,
      getValues,
      fields,
      onSubmit,
      addElement,
      calculateTotal,
      removeInputElement,
      setCalcByArea,
      resetEntryLengthAndWidth,
      resolveDuplicateEntryNames,
      setAddAdditionalCoverage,
      undoRemoveElement,
      updateElement,
      resetCalculatorState
    },
  ] = useCalculator({
    allowAdditionalCoverage,
    calculatorType,
    isFlooringDrawer,
    info,
    l1Label,
    lineItemName,
    onCalculate,
    unitsPerCase,
  });

  const { addToaster, removeToaster } = useContext(ToasterContext);
  const addSavedAreaToaster = ({ selectedArea }) => {
    const entries = getValues('entries');
    const { id: key, name } = entries[selectedArea];
    const saveKey = `save-${key}`;
    addToaster(
      saveKey,
      {
        children: toasterContent({ areaName: name, actionPerformed: 'has been saved' }),
        timeoutKey: saveKey,
        showCloseButton: true,
        status: 'success'
      }
    );
  };

  const addRemovedAreaToaster = ({ selectedArea }) => {
    const entries = getValues('entries');
    const { name } = selectedArea !== null ? entries[selectedArea] : { key: 'all' };
    const { id } = selectedArea !== null ? fields[selectedArea] : { id: null };
    const deleteKey = `delete-${id}`;

    addToaster(deleteKey, {
      action: (
        <div className="sui-flex sui-mr-4">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            color="info"
            component="button"
            onClick={() => {
              undoRemoveElement(id);
              removeToaster({ key: deleteKey });
            }}
            underline="always"
            weight="bold"
          >
            UNDO
          </Link>
        </div>
      ),
      children: name
        ? toasterContent({ areaName: name, actionPerformed: 'has been deleted' })
        : <>All areas have been deleted</>,
      timeoutKey: deleteKey,
      status: 'success'
    });
  };

  const addUnsavedChangesToaster = ({ selectedArea }) => {
    const entries = getValues('entries');
    const entryIndex = selectedArea !== null ? selectedArea : entries.length - 1;

    const { name } = entries[entryIndex];
    const unchangedKey = `save-${entryIndex}`;
    const actionMsg = 'was not saved';

    addToaster(
      unchangedKey,
      {
        children: toasterContent({ areaName: name, actionPerformed: actionMsg }),
        timeoutKey: unchangedKey,
        showCloseButton: true,
        status: 'warning'
      }
    );
  };

  return (
    <CalculatorContext.Provider
      value={{
        control,
        getValues,
        fields,
        addElement,
        calculate,
        calculateTotal,
        calculatorType,
        isFlooringDrawer,
        l1Label,
        lineItemName,
        removeInputElement,
        setCalcByArea,
        resetEntryLengthAndWidth,
        resolveDuplicateEntryNames,
        setAddAdditionalCoverage,
        onSubmit,
        updateElement,
        addSavedAreaToaster,
        addRemovedAreaToaster,
        addUnsavedChangesToaster,
        resetCalculatorState,
        ..._testOverrides
      }}
    >
      <form onSubmit={onSubmit} className="sui-flex sui-grow sui-flex-col">
        {children}
        <input
          inert="true"
          type="submit"
          className="sui-hidden"
        />
      </form>
    </CalculatorContext.Provider>
  );
};

CalculatorProvider.propTypes = {
  allowAdditionalCoverage: bool,
  calculatorType: string,
  isFlooringDrawer: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
  info: shape({
    pipCalculator: shape({
      coverageUnits: string, // Dimensional units Ex: "Panel", "Block", "Sheet", "Bag"
      display: bool, // Whether or not to display the calculator
      publisher: number, // units per case
      toggle: string, // "2-Point" (sq. ft.), "3-Point" (cu. ft.), or "Fixed_Width" (linear ft.)
      defaultAdditionalCoverage: bool, // Whether additional coverage is used by default
      additionalCoveragePercentage: number, // The additional coverage percentage
    }),
  }),
  lineItemName: string,
  onCalculate: func,
  taxonomy: shape({
    breadCrumbs: arrayOf(
      shape({
        label: string,
      })
    ),
  }).isRequired,
  unitsPerCase: oneOfType([string, number]),
  _testOverrides: shape({}) // Used to pass mock functions to the provider for testing purposes
};

CalculatorProvider.defaultProps = {
  allowAdditionalCoverage: false,
  calculatorType: '',
  isFlooringDrawer: false,
  info: {},
  lineItemName: 'Area',
  onCalculate: null,
  unitsPerCase: '0',
  _testOverrides: {}
};

export default CalculatorProvider;
